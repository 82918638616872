<template>
<div class="tgju-widget-content full-padding">
    <slot>
        <div class="list-widget list-dash-items list-widget-structure">
            <slot v-if="market_values.length == 0">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </slot>
            <div v-else class="tgju-widget light">
                <table class="table table-hover text-center">
                    <thead class="text-center">
                        <tr style="width: 618px;">
                            <th style="width: 70px;">عنوان</th>
                            <th style="width: 70px;">قیمت زنده</th>
                            <th style="width: 70px;">تغییر</th>
                            <th style="width: 70px;">کمترین</th>
                            <th style="width: 70px;">بیشترین</th>
                            <th style="width: 70px;">زمان</th>
                            <th style="width: 70px;"></th>
                        </tr>
                    </thead>
                    <tbody class="table-padding-sm">
                        <tr style="width: 618px;" v-for="market_value in market_values" :key="market_value.id">
                            <td class="text-right dot" style="width: 70px;">
                                <router-link :to="{ name: 'MarketProfile', params: { name : market_value.item_id } }" class="l-item-btn-color-blue">
                                    {{ market_value.title }}
                                </router-link>
                            </td>
                            <td style="width: 70px;">
                                {{ formatPrice(market_value.p) }}
                            </td>
                            <td style="width: 70px;">
                                <div class="list-col l-title l-title-widget-structure">
                                    <span v-if="market_value.dt == 'high' " class="high color-green badge-green dir">
                                        {{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                    <span v-else-if="market_value.dt == 'low' " class="low color-red badge-red dir">
                                        {{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                    <span v-else>{{ formatPrice(market_value.d) }} ( {{ market_value.dp  }}% )</span>
                                </div>
                            </td>
                            <td style="width: 70px;">
                                {{ formatPrice(market_value.l) }}
                            </td>
                            <td style="width: 70px;">
                                {{ formatPrice(market_value.h) }}
                            </td>
                            <td style="width: 70px;">
                                {{ market_value.t }}
                            </td>
                            <td style="width: 70px;">
                                <!-- <span class="fa fa-eye" @click="openMarketAddToWatchListModal(market_value.name)"></span> -->
                                <div class="dropdown">
                                    <i class="uil uil-list-ui-alt" @click="dropDown(market_value.name)"></i>
                                    <div :id="'myDropdown-'+ market_value.name" class="d-none">
                                        <ul>
                                            <li>
                                                <router-link :to="{ name: 'MarketProfile', params: { name : market_value.item_id } }" class="l-item-btn-color-blue">
                                                    پروفایل
                                                </router-link>
                                            </li>
                                            <li>
                                                <span @click="openMarketAddToWatchListModal(market_value.item_id)" style="cursor: pointer;">
                                                    واچ لیست
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </slot>
</div>
</template>

<style lang="scss" scoped>
.show {
    position: absolute;
    background-color: #f1f1f1;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 2px;
}

thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.high.dir,
.low.dir {
    max-height: 25px;
    margin: 10px;
}
</style>

<script>
// این کامپوننت اجزای صفحه استاتیک بازار را در خود جای میدهد
import moment from "moment-jalaali";
import Centrifuge from "centrifuge";

export default {
    name: 'MarketStaticComponent',
    props: ['data'],
    data: function () {
        return {
            intervalId: undefined,
            market_values: [],
            trade_details: [],
            market_ids: [],
            widgetLoad: false,
            refresh: 0,
            shows: [],
        }
    },
    created() {
        this.widgetLoad = true;
        this.market_ids = this.data.join();
    },
    mounted() {
        this.$watch('widgetLoad', function (newValue, oldValue) {
            this.getMarketValue();
            // this.socketMarket();
        }, {
            immediate: true
        });
    },
    methods: {
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در صفحه را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, market_item] of Object.entries(self.market_values)) {
                            if (market_item.item_id == item[1]) {
                                self.market_values[index].d = item[10];
                                self.market_values[index].dp = item[11];
                                self.market_values[index].dt = item[12];
                                self.market_values[index].h = item[7];
                                self.market_values[index].l = item[8];
                                self.market_values[index].p = item[6];
                                self.market_values[index].t = item[13];
                            }
                        }
                    }
                }
            });
            centrifuge.connect();
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد برای دریافت اطلاعات از سرور میباشد
        getMarketValue() {
            this.$helpers.makeRequest('GET', '/market/list', {
                market_ids: this.market_ids
            }).then(api_response => {
                if (api_response.status == 200) {
                    this.market_values = api_response.data.response.detail;
                }
            });
        },
        // این متد برای باز کردن مودال افزودن نماد به واچ لیست و هندل کال بک آن مورد استفاده قرار میگیرد
        openMarketAddToWatchListModal(symbol) {
            let callbackEvent = this.$router.currentRoute.name + '_MarketAddToWatchList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MarketAddToWatchList.vue',
                config: {
                    title: 'افزودن نماد به واچ لیست',
                    smallModal: true,
                    data: {
                        symbol: symbol
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);
            // هندل کردن کال بک 
            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addMarketToWatchList') {
                    this.$helpers.makeRequest('POST', '/watchlist/add-remove-market-to-watch-list', {
                        watch_list_id: response.data.category_id,
                        market_symbol: symbol
                    }).then(api_response => {
                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: api_response.data.response.status == 'add-success' ? 'با موفقیت افزوده شد' : 'با موفقیت حذف شد',
                        });
                    });
                }
            });
        },
        // این متد برای هندل کردن منوی دراپ دان لیست می باشد
        dropDown(name) {
            var el = document.getElementById("myDropdown-" + name);

            if (el.classList.contains('d-none')) {
                el.classList.remove('d-none');
                el.classList.toggle('show');
                return 0;
            }

            if (el.classList.contains('show')) {
                el.classList.remove('show');
                el.classList.toggle('d-none');
                return 0;
            }
        },
    },
}
</script>
